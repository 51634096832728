import React from "react";
import {
  FaBookOpen,
  FaCode,
  FaDiscord,
  FaQuestion,
  FaTag,
} from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import type { IconType } from "react-icons/lib";
import { LuLayoutDashboard, LuMenu } from "react-icons/lu";

import { LogoWithText } from "@/components/logo/logo-with-text";
import { Button } from "@/components/ui/button";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { cn } from "@/lib/utils";

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & { icon?: IconType }
>(({ className, title, children, ...props }, ref) => {
  const { icon: Icon } = props;
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground flex select-none gap-3 space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors",
            className,
          )}
          {...props}
        >
          {Icon && (
            <div className="bg-secondary text-secondary-foreground flex h-10 w-10 items-center justify-center rounded-full">
              <Icon className="h-6 w-6" />
            </div>
          )}
          <div>
            <div className="text-sm font-medium leading-none">{title}</div>
            <p className="text-muted-foreground line-clamp-2 text-sm leading-snug">
              {children}
            </p>
          </div>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";

export const navItems = [
  // {
  //   section: "Features",
  //   items: [
  //     {
  //       title: "Rules",
  //       href: "/features/rules",
  //       description: "List of behavior rules",
  //       icon: FaListAlt,
  //     },
  //     {
  //       title: "Analytics",
  //       href: "/features/analytics",
  //       description: "Detailed analytics for your links",
  //       icon: FaChartLine,
  //     },
  //     {
  //       title: "Custom Domains",
  //       href: "/features/custom-domains",
  //       description: "Custom domains for your links",
  //       icon: FaGlobe,
  //     },
  //     {
  //       title: "Content Delivery Network",
  //       href: "/features/cdn",
  //       description: "Global CDN for your links",
  //       icon: FaNetworkWired,
  //     },
  //     {
  //       title: "Templates",
  //       href: "/features/templates",
  //       description: "Template support for your links",
  //       icon: FaColumns,
  //     },
  //     {
  //       title: "Integrations",
  //       href: "/features/integrations",
  //       description: "App integrations",
  //       icon: FaCogs,
  //     },
  //   ],
  // },
  {
    section: "Resources",
    items: [
      {
        title: "Blog",
        href: "/blog",
        description: "Posts",
        icon: FaBookOpen,
      },
      {
        title: "Help",
        href: "https://" + import.meta.env.PUBLIC_DOCS_DOMAIN + "/help-center",
        description: "Center",
        icon: FaQuestion,
      },
      {
        title: "Discord",
        href: "https://smart-l.ink/discord",
        description: "Server",
        icon: FaDiscord,
      },
      {
        title: "Developers",
        href: "https://" + import.meta.env.PUBLIC_DOCS_DOMAIN + "/developers",
        description: "Documentation",
        icon: FaCode,
      },
    ],
  },
  {
    section: "Tools",
    items: [
      {
        title: "URL Shortener",
        href: "/tools/url-shortener",
        description: "Shorten your long URLs",
        icon: FaLink,
      },
    ],
  },
  {
    section: "Pricing",
    href: "/pricing",
    icon: FaTag,
  },
];

export default function Navbar({ className }: { className?: string }) {
  return (
    <nav className={cn("bg-background block border-b", className)}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <a
            className="flex flex-shrink-0 items-center"
            href="/"
            aria-label="Smart-Link"
          >
            <LogoWithText
              topClassName="fill-primary"
              bottomClassName="fill-foreground"
              className="h-8"
            />
          </a>
          <div className="hidden flex-1 px-2 md:flex md:items-center md:justify-center">
            <NavigationMenu>
              <NavigationMenuList className="dark:text-foreground">
                {navItems.map((item) =>
                  item.href ? (
                    <NavigationMenuItem key={item.href}>
                      <NavigationMenuLink
                        href={item.href}
                        className={navigationMenuTriggerStyle()}
                      >
                        {item.section}
                      </NavigationMenuLink>
                    </NavigationMenuItem>
                  ) : (
                    <NavigationMenuItem key={item.section}>
                      <NavigationMenuTrigger>
                        {item.section}
                      </NavigationMenuTrigger>
                      <NavigationMenuContent>
                        <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                          {item.items?.map((service) => (
                            <ListItem
                              key={service.title}
                              title={service.title}
                              href={service.href}
                              icon={service.icon}
                            >
                              {service.description}
                            </ListItem>
                          ))}
                        </ul>
                      </NavigationMenuContent>
                    </NavigationMenuItem>
                  ),
                )}
              </NavigationMenuList>
            </NavigationMenu>
          </div>
          <div className="hidden md:flex md:items-center">
            <a
              href={"//" + import.meta.env.PUBLIC_DASHBOARD_DOMAIN}
              rel="nofollow"
            >
              <Button variant={"secondary"}>
                <LuLayoutDashboard className="mr-2 h-4 w-4" />
                Dashboard
              </Button>
            </a>
          </div>

          <div className="flex items-center md:hidden">
            <Sheet>
              <SheetTrigger asChild>
                <Button
                  variant="ghost"
                  className="text-foreground px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden"
                >
                  <LuMenu className="h-5 w-5" />
                  <span className="sr-only">Toggle Menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent side="right">
                <SheetHeader>
                  <SheetTitle>Menu</SheetTitle>
                </SheetHeader>
                <ScrollArea className="h-full w-full pb-4">
                  <nav className="flex flex-col space-y-2">
                    {navItems.map((section) =>
                      section.href ? (
                        <div key={section.section} className="py-2">
                          <a
                            href={section.href}
                            className="hover:bg-accent hover:text-accent-foreground flex items-center space-x-2 rounded-md px-2 py-2 text-base font-medium"
                          >
                            <section.icon className="h-5 w-5" />
                            <span>{section.section}</span>
                          </a>
                          {section !== navItems[navItems.length - 1] && (
                            <Separator className="mt-2" />
                          )}
                        </div>
                      ) : (
                        <div key={section.section} className="py-2">
                          <h2 className="mb-2 px-2 text-lg font-semibold tracking-tight">
                            {section.section}
                          </h2>
                          <div className="space-y-1">
                            {section.items?.map((item) => (
                              <a
                                key={item.title}
                                href={item.href}
                                className="hover:bg-accent hover:text-accent-foreground flex items-center space-x-2 rounded-md px-2 py-2 text-base font-medium"
                              >
                                <item.icon className="h-5 w-5" />
                                <span>{item.title}</span>
                              </a>
                            ))}
                          </div>
                          {section !== navItems[navItems.length - 1] && (
                            <Separator className="mt-2" />
                          )}
                        </div>
                      ),
                    )}
                  </nav>
                  <div className="mt-auto pt-4">
                    <Separator className="mb-4" />
                    <a href={"//" + import.meta.env.PUBLIC_DASHBOARD_DOMAIN}>
                      <Button className="w-full" variant="secondary">
                        <LuLayoutDashboard className="mr-2 h-5 w-5" />
                        Dashboard
                      </Button>
                    </a>
                  </div>
                </ScrollArea>
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </div>
    </nav>
  );
}
